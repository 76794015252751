/**
 * admin/auth 为授权模块
 */

import request from "@/utils/request";
import type { IMenu } from "@/api/admin/menu/types";
import { useUserStore } from "@/store/modules/user";
import type { PageContent } from '@/api/types';
import type { IRole } from "@/api/admin/role/types";
//TODO urls 改为复数
/**
 * 获取路由列表
 */
export function listRoutesApi(): Promise<[boolean,IMenu]>  {
    return request({
      url: useUserStore().isAdmin() ? "/menus/system" : "/menus/home",
      method: 'get',
    });
  }
/**
 * /admin/users/{id}/objects/{object_rule_id}
 * OBJECT RULE {类名, 权限规则: 部门|用户|字段}
 * 此API只返回权限规则是 部门 的 组织机构 数据 及用户权限
 */
export function listOrganizationsWithUserForBusinessApi(userid:number,oid:number):Promise<PageContent> {
  return request({
    url: `/admin/users/${userid}/objects/${oid}`,
    method: 'get',
  });
}
export function createOrganizationForUserBusinessApi(userid:number,oid:number,orgId:number|string, includeAll:boolean=false) : Promise<boolean>{
  return request({
    url: `/admin/users/${userid}/objects/${oid}/orgs/${orgId}?includeAll=${includeAll}`,
    method: 'post',
  });
}
export function removeOrganizationForUserBusinessApi(userid:number,oid:number,orgId:number|string) : Promise<boolean>{
  return request({
    url: `/admin/users/${userid}/objects/${oid}/orgs/${orgId}`,
    method: 'delete',
  });
}
export function listOrganizationsWithPositionForBusinessApi(userid:number,oid:number):Promise<PageContent> {
  return request({
    url: `/admin/positions/${userid}/objects/${oid}`,
    method: 'get',
  });
}
export function createOrganizationForPositionBusinessApi(userid:number,oid:number,orgId:number|string, includeAll:boolean=false) : Promise<boolean>{
  return request({
    url: `/admin/positions/${userid}/objects/${oid}/orgs/${orgId}?includeAll=${includeAll}`,
    method: 'post',
  });
}
export function removeOrganizationForPositionBusinessApi(userid:number,oid:number,orgId:number|string) : Promise<boolean>{
  return request({
    url: `/admin/positions/${userid}/objects/${oid}/orgs/${orgId}`,
    method: 'delete',
  });
}
export function listOrganizationsWithRoleForBusinessApi(roleid:number,oid:number):Promise<PageContent> {
  return request({
    url: `/admin/roles/${roleid}/objects/${oid}`,
    method: 'get',
  });
}
export function createOrganizationForRoleBusinessApi(roleid:number,oid:number,orgId:number|string, includeAll:boolean=false) : Promise<boolean>{
  return request({
    url: `/admin/roles/${roleid}/objects/${oid}/orgs/${orgId}?includeAll=${includeAll}`,
    method: 'post',
  });
}
export function removeOrganizationForRoleBusinessApi(roleid:number,oid:number,orgId:number|string) : Promise<boolean>{
  return request({
    url: `/admin/roles/${roleid}/objects/${oid}/orgs/${orgId}`,
    method: 'delete',
  });
}
/**
 * /admin/users/{id}/objects/{object_rule_id}/fields
 * OBJECT RULE {类名, 权限规则: 部门|用户|字段}
 * 此API只返回权限规则是 字段 的 业务对象的字段 数据 及 用户权限
 */
export function listFieldsWithUserForBusinessApi(userid:number,oid:number):Promise<PageContent> {
  return request({
    url: `/admin/users/${userid}/objects/${oid}/fields`,
    method: 'get',
  });
}
export function createFieldForUserBusinessApi(userid:number,oid:number,field:number|string) : Promise<boolean>{
  return request({
    url: `/admin/users/${userid}/objects/${oid}/fields/${field}`,
    method: 'post',
  });
}
export function removeFieldForUserBusinessApi(userid:number,oid:number,field:number|string) : Promise<boolean>{
  return request({
    url: `/admin/users/${userid}/objects/${oid}/fields/${field}`,
    method: 'delete',
  });
}
/**
 * 返回 菜单 数据 及 用户权限
 */
export function listMenusWithUserApi(userid:number):Promise<PageContent> {
  return request({
    url: `/admin/users/${userid}/menus`,
    method: 'get',
  });
}
export function createMenuForUserApi(userid:number, menuid:number|string, includeAll:boolean=false): Promise<boolean>{
  return request({
    url: `/admin/users/${userid}/menus/${menuid}?includeAll=${includeAll}`,
    method: 'post',
  });
}
export function removeMenuForUserApi(userid:number, menuid:number|string): Promise<boolean>{
  return request({
    url: `/admin/users/${userid}/menus/${menuid}`,
    method: 'delete',
  });
}

export function listMenusWithPositionApi(userid:number):Promise<PageContent> {
  return request({
    url: `/admin/positions/${userid}/menus`,
    method: 'get',
  });
}
export function createMenuForPositionApi(userid:number, menuid:number|string, includeAll:boolean=false): Promise<boolean>{
  return request({
    url: `/admin/positions/${userid}/menus/${menuid}?includeAll=${includeAll}`,
    method: 'post',
  });
}
export function removeMenuForPositionApi(userid:number, menuid:number|string): Promise<boolean>{
  return request({
    url: `/admin/positions/${userid}/menus/${menuid}`,
    method: 'delete',
  });
}
export function listFieldsWithPositionForBusinessApi(userid:number,oid:number):Promise<PageContent> {
  return request({
    url: `/admin/positions/${userid}/objects/${oid}/fields`,
    method: 'get',
  });
}
export function createFieldForPositionBusinessApi(userid:number,oid:number,field:number|string) : Promise<boolean>{
  return request({
    url: `/admin/positions/${userid}/objects/${oid}/fields/${field}`,
    method: 'post',
  });
}
export function removeFieldForPositionBusinessApi(userid:number,oid:number,field:number|string) : Promise<boolean>{
  return request({
    url: `/admin/positions/${userid}/objects/${oid}/fields/${field}`,
    method: 'delete',
  });
}
export function listUrlForRoleApi(roleid:number):Promise<number[]> {
  return request({
    url: `/admin/roles/${roleid}/urls/selected`,
    method: 'get',
  });
}
export function createUrlForRoleApi(roldid:number, urlid:number|string): Promise<boolean>{
  return request({
    url: `/admin/roles/${roldid}/urls/${urlid}`,
    method: 'post',
  });
}
export function removeUrlForRoleApi(roldid:number, urlid:number|string): Promise<boolean>{
  return request({
    url: `/admin/roles/${roldid}/urls/${urlid}`,
    method: 'delete',
  });
}
export function listMenusWithRoleApi(roldid:number):Promise<PageContent> {
  return request({
    url: `/admin/roles/${roldid}/menus`,
    method: 'get',
  });
}
export function createMenuForRoleApi(roldid:number, menuid:number|string, includeAll:boolean=false): Promise<boolean>{
  return request({
    url: `/admin/roles/${roldid}/menus/${menuid}?includeAll=${includeAll}`,
    method: 'post',
  });
}
export function removeMenuForRoleApi(roldid:number, menuid:number|string): Promise<boolean>{
  return request({
    url: `/admin/roles/${roldid}/menus/${menuid}`,
    method: 'delete',
  });
}
export function listFieldsWithRoleForBusinessApi(roldid:number,oid:number):Promise<PageContent> {
  return request({
    url: `/admin/roles/${roldid}/objects/${oid}/fields`,
    method: 'get',
  });
}
export function createFieldForRoleBusinessApi(roldid:number,oid:number,field:number|string) : Promise<boolean>{
  return request({
    url: `/admin/roles/${roldid}/objects/${oid}/fields/${field}`,
    method: 'post',
  });
}
export function removeFieldForRoleBusinessApi(roldid:number,oid:number,field:number|string) : Promise<boolean>{
  return request({
    url: `/admin/roles/${roldid}/objects/${oid}/fields/${field}`,
    method: 'delete',
  });
}

/**
 * 返回 角色 数据 及 用户权限
 */
export function listRolesWithUserApi(userid:number):Promise<PageContent> {
  return request({
    url: `/admin/users/${userid}/roles`,
    method: 'get',
  });
}
export function createRoleForUserApi(userid:number, roleid:number|string, includeAll:boolean=false): Promise<boolean>{
  return request({
    url: `/admin/users/${userid}/roles/${roleid}?includeAll=${includeAll}`,
    method: 'post',
  });
}
export function removeRoleForUserApi(userid:number, roleid:number|string): Promise<boolean>{
  return request({
    url: `/admin/users/${userid}/roles/${roleid}`,
    method: 'delete',
  });
}
export function listRolesWithPositionApi(userid:number):Promise<PageContent> {
  return request({
    url: `/admin/positions/${userid}/roles`,
    method: 'get',
  });
}
export function createRoleForPositionApi(userid:number, roleid:number|string, includeAll:boolean=false): Promise<boolean>{
  return request({
    url: `/admin/positions/${userid}/roles/${roleid}?includeAll=${includeAll}`,
    method: 'post',
  });
}
export function removeRoleForPositionApi(userid:number, roleid:number|string): Promise<boolean>{
  return request({
    url: `/admin/positions/${userid}/roles/${roleid}`,
    method: 'delete',
  });
}
/**
 * TODO
 * url -> urls
 * @param urlid 
 * @returns 
 */
export function listRolesWithUrlApi(urlid:number):Promise<IRole[]>  {
  return request({
    url: `/admin/urls/${urlid}/auths`,
    method: 'get',
  });
}

export function reloadAuthApi(): Promise<boolean> {
  return request({
    url: '/admin/auths/reload',
    method: 'get',
  });
}