import Applications from "@@/machine/views/applications.vue";

export default {
  Config1: "比价设置",
  Config2: "上架设置",
  Config3: "Token设置",
  Brands: "品牌",
  Sizes: "尺码管理",
  DewuSpus: "得物商品",
  DewuSkus: "得物商品详情",
  StockxSpus: "绿叉商品",
  StockxSkus: "绿叉商品详情",
  Tasks: "比价任务",
  TaskDetails: "任务明细",
  PriceDetails: "比价明细",
  GongxieConfig: "共鞋配置",
  GongxieTasks: "共鞋任务",
  GongxieDetails: "共鞋明细",
  GongxieDatas: "共鞋数据",
  GoatTasks: "品牌任务",
  GoatDetails: "品牌明细",
  GoatDatas: "品牌数据",
  DewuTasks: "得物任务",
  DewuDetails: "得物明细",
  DewuDatas: "得物数据",
  StockxTasks: "绿叉任务",
  StockxDetails: "绿叉明细",
  StockxDatas: "绿叉数据",


}