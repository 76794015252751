import type { PageContent } from '@/api/types';
import request from "@/utils/request";
import type { IServerConfig, ISystemInfo } from "./types";
import {appendToken} from "@/utils/request"

export function listApi(queryParams: Object): Promise<PageContent> {
    return request({
        url: "/admin/server/configs",
        method: 'get',
        params: queryParams
    });
}
export function listWithKeysApi(keys:string[]): Promise<IServerConfig[]> {
    return request({
        url: "/admin/server/configs/keys",
        method: 'post',
        data: keys
    });
}
/**
 * @param data 
 * @returns id for data
 */
export function createOrUpdateApi(data: IServerConfig): Promise<number> {
    return request({
        url: "/admin/server/configs",
        method: 'post',
        data: data
    });
}
export function saveAllApi(configs: IServerConfig[]): Promise<number> {
    return request({
        url: "/admin/server/configs/save",
        method: 'post',
        data: configs
    });
}
//TODO url -> server/configs
export function deleteApi(id: number): Promise<boolean> {
    return request({
        url: `/admin/server/configs/${id}`,
        method: 'delete',
    });
}

/**
 * @param data 
 * @returns id for data
 */
export function getSystemInfoApi(): Promise<ISystemInfo> {
    return request({
        url: "/server/systeminfo",
        method: 'get',
    });
}
/**
 * @param data 
 * @returns id for data
 */
export function saveSystemInfoApi(data: ISystemInfo): Promise<number> {
    return request({
        url: "/admin/systeminfo",
        method: 'post',
        data: data
    });
}
export function uploadLogoApi(data:any) {
    return request({
        url: `/admin/systeminfo/logo`,
        method: 'post',
        transformRequest: [function(data, headers) {
            // 去除post请求默认的Content-Type
            delete headers['Content-Type']
            return data
          }],
        headers:  { enctype: 'multipart/form-data' },
        data: data,
    });
}
export function getLogoURLApi(): string {
    return  appendToken(`/server/logo`)
}
export function getLogoApi(): Promise<Blob> {
    return request({
        url: `/server/logo`,
        method: 'get'
    });
}