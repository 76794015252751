// 线程锁
let isLock = false;
let lockList:any = [];
export async function init() {
   isLock = false;
   lockList = [];
}
export async function lock():Promise<Function> {
  function unlock() {
    let waitFunc = lockList.shift();
    if (waitFunc) {
      waitFunc.resolve(unlock);
    } else {
      isLock = false;
    }
    console.log("unlock...")
  }
  console.log("get locker...")
  if (isLock) {
    return new Promise((resolve, reject) => {
      lockList.push({ resolve, reject });
    });
  } else {
    isLock = true;
    return unlock;
  }
}
export async function getLock():Promise<Function> {
    let unlock = await lock();
    return unlock;
}

export function timeout(promise, ms) {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new Error(`Operation timed out:${ms}ms`));
    }, ms);
 
    promise.then(
      (value) => {
        clearTimeout(timeoutId);
        resolve(value);
      },
      (error) => {
        clearTimeout(timeoutId);
        reject(error);
      }
    );
  });
}