// translate router.meta.title, be used in breadcrumb sidebar tagsview
import i18n from '@/lang/index';


export function translateRouteTitle(title: any) {
  return generateTitle('route',title)
}
export function generateTitle(prefix: string, title: string) {
  const hasKey = i18n.global.te(prefix + '.' + title)
  if (hasKey) {
    return i18n.global.t(prefix + '.' + title) 
  }
  return title
};
export function t(key:any, args={}) {
  return i18n.global.t(key,args)
}