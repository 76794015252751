<template>

    <span style="position:relative">
      <transition name="lemon-slide-top">
          <div v-if="visible"
            class="lemon-popover"
            ref="popover"
            style={this.popoverStyle}
            @click="e => e.stopPropagation()"
          >
            <div class="lemon-popover__content">
              <slot name="content"></slot>
            </div>
            <div class="lemon-popover__arrow" />
          </div>
      </transition>
      <slot name="default"></slot>
    </span>
</template>
<script>
const popoverCloseQueue = [];
import contextmenu from "../directives/contextmenu";
const triggerEvents = {
  hover(el) {},
  focus(el) {
    el.addEventListener("focus", e => {
      this.changeVisible();
    });
    el.addEventListener("blur", e => {
      this.changeVisible();
    });
  },
  click(el) {
    el.addEventListener("click", e => {
      e.stopPropagation();
      contextmenu.hide();
      this.changeVisible();
    });
  },
  contextmenu(el) {
    el.addEventListener("contextmenu", e => {
      e.preventDefault();
      this.changeVisible();
    });
  }
};
export default {
  name: "LemonPopover",
  props: {
    trigger: {
      type: String,
      default: "click",
      validator(val) {
        return Object.keys(triggerEvents).includes(val);
      }
    }
  },
  data() {
    return {
      popoverStyle: {},
      visible: false
    };
  },
  created() {
    document.addEventListener("click", this._documentClickEvent);
    popoverCloseQueue.push(this.close);
  },
  mounted() {
    triggerEvents[this.trigger].call(this, this.$slots.default[0].elm);
  },
  destroyed() {
    document.removeEventListener("click", this._documentClickEvent);
  },
  computed: {},
  watch: {
    async visible(val) {
      if (val) {
        await this.$nextTick();
        const defaultEl = this.$slots.default[0].elm;
        const contentEl = this.$refs.popover;

        this.popoverStyle = {
          top: `-${contentEl.offsetHeight + 10}px`,
          left: `${defaultEl.offsetWidth / 2 - contentEl.offsetWidth / 2}px`
        };
      }
    }
  },
  methods: {
    _documentClickEvent(e) {
      e.stopPropagation();
      if (this.visible) this.close();
    },
    changeVisible() {
      this.visible ? this.close() : this.open();
    },
    open() {
      this.closeAll();
      this.visible = true;
    },
    closeAll() {
      popoverCloseQueue.forEach(callback => callback());
    },
    close() {
      this.visible = false;
    }
  }
};
</script>
