// 路由

export default {
  //ERP
  "Erp": "ERP用户",
  "Shop": "SHOP用户",
  "Seller": "店员",
  "Cashier": "收单",
  "Basics": "基础资料",
  "Products": "产品资料",
  "Contacts": "联系人资料",
  "Customers": "客户资料",
  "Suppliers": "供应商资料",
  "Dealers": "经销商资料",
  "Warehouses": "仓库资料",
  "Others": "辅助资料",
  "Sales": "销售模块",
  "SalesOrders": "销售管理",
  "SalesQuotes": "报价管理",
  "Deliveries": "送货管理",
  "SalesReturns": "销售退货管理",
  "MyOrders": "我的管理",
  "Purchases": "采购模块",
  "PurchaseOrders": "采购管理",
  "Inquiries": "询价管理",
  "PurchaseReturns": "采购退货管理",
  "Inventories": "库存模块",
  "ApplyProducts": "产品申请",
  "Stocks": "产品库存",
  "Inbounds": "入库管理",
  "Outbounds": "出库管理",
  "Transfers": "调拨管理",
  "Accounts": "财务账户",
  "Audits": "审核模块",
  "SalesAudit": "销售审核",
  "PurchaseAudit": "采购审核",
  "InboundAudit": "入库审核",
  "OutboundAudit": "出库审核",
  "TransferAudit": "调拨审核",
  "Statistics": "统计模块",
  "SalesStatistics": "销售统计",
  "PurchaseStatistics": "采购统计",
  "Ordered": "销售产品统计",
  "Purchased": "采购产品统计",
  "ProductStatistics": "产品矩阵统计"
}
