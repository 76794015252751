import admin from "./admin"
import activiti from "./activiti"
import erp from "./erp"
import device from "./device"

export default {
  ...admin,
  ...activiti,
  ...erp,
  ...device
}
