export default {

  //流程管理
  activiti: {
    submit: {
      success: "已提交",
      fail: "提交失败"
    },
    datagrid: {
      placeholder: {

      },
      label: {
        Model: "流程图",
        Design: "设计",
        Records: "审核信息"
      },
      title: {

      },
      message: {
        
      },
      dialog: {

      },
      row: {
        Import: "部署",
        Model: "流程图"
      }
    },
    definition: {
      placeholder: {

      },
      datagrid: {
        placeholder: {

        },
        label: {
          ID: "流程ID",
          KEY: "流程KEY",
          Name: "流程名称",
          Version: "版本",
          Category: "分类",
          Forms: "表单"
        },
        title: {
  
        },
        message: {
          
        },
        dialog: {
  
        },
        row: {
  
        }
      }
    },
    deployment: {
      placeholder: {

      },
      datagrid: {
        placeholder: {

        },
        label: {
          ID: "部署ID",
          Time: "部署时间",
          KEY: "部署KEY",
          Name: "部署名称",
          Version: "版本",
          Category: "分类",
          
        },
        title: {
  
        },
        message: {
          
        },
        dialog: {
  
        },
        row: {
  
        }
      }
    },
    instance: {
      placeholder: {

      },
      datagrid: {
        placeholder: {

        },
        label: {
          ID: "实例ID",
          KEY: "业务KEY",
          Name: "实例名称",
          Version: "版本",
          Reason: "原因",
          StartTime: "开始时间",
          EndTime: "结束时间",
          Starter: "发起人"
        },
        title: {
  
        },
        message: {
          
        },
        dialog: {
  
        },
        row: {
  
        }
      }
    },
    task: {
      placeholder: {

      },
      datagrid: {
        placeholder: {

        },
        label: {
          ID: "任务ID",
          KEY: "业务KEY",
          Name: "任务名称",
          Assignee: "处理人",
          Title:"标题",
          Refuse: "拒绝",
          Approve: "同意",
          Submit: "提交",
          Reason: "原因",
          StartTime: "开始时间",
          EndTime: "结束时间"
        },
        title: {
  
        },
        message: {
          
        },
        dialog: {
  
        },
        row: {
  
        }
      }
    },
    dynamicform: {
      datagrid: {
        placeholder: {
  
        },
        label: {
          FormName: "表单名称",
          FormRule: "表单规则",
          Category: "分类",
          ProcessVariables: "流程变量",
          Organizations: "部门"
        },
        title: {
  
        },
        message: {
          create: {
            success: "表单已创建",
            fail: "表单创建失败"
          },
          update: {
            success: "表单已保存",
            fail: "表单保存失败"
          }
        },
        dialog: {
  
        },
        row: {
  
        }
      },
    }
  },

  template: {
    datagrid: {
      placeholder: {

      },
      label: {

      },
      title: {

      },
      message: {
        
      },
      dialog: {

      },
      row: {

      }
    },
  }

};
