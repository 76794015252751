import { defineStore } from "pinia";

import { loginApi, aloginApi, logoutApi } from "@/api/auth";
import { getUserInfo } from "@/api/auth";
import { store } from "@/store";

import { LoginData } from "@/api/auth/types";

import { RemovableRef, useStorage } from "@vueuse/core";
import { ref } from "vue";
import { useAppStore } from "./app";
import { IPasswordMap, IUser, Principal } from "@/api/admin/user/types";
import { savePasswordApi, getMyAvatarURL } from "@/api/admin/user";
import {
  getUnreadMessageCountApi

} from "@/api/message";


export const useUserStore = defineStore("user", () => {
  // state
  const avatar = ref("avatar.gif");
  const username = useStorage("username", "");
  const showName = useStorage("showName", "");
  const principal:RemovableRef<Principal> = useStorage("principal",null, sessionStorage, {
    serializer: {
      read: (v: string) => {
        return JSON.parse(v);
      },
      write: (v: Principal) => {
        if (!v) return ""
        return JSON.stringify(v)
      },
    }
  });
  
  const messageCount = useStorage("messageCount", 0);

  const aloginToken = useStorage("aloginToken", null);
  /**
   * 登录调用
   *
   * @param {LoginData}
   * @returns
   */
  function login(loginData: LoginData) {
    return new Promise<IUser>((resolve, reject) => {
      loginApi(loginData)
        .then((res) => { 
            if (res.login) {
              aloginToken.value = res.login;
            }
            username.value = loginData.username;
          
           getInfo().then((data)=>resolve(data)); 
          })
        .catch((error) => {
          reject(error);
        });
    });
  }
  function autoLogin() {
    return new Promise<boolean>((resolve, reject) => {
      if (!aloginToken.value) {
        reject("no aloginToken");
        return
      }
      aloginApi(aloginToken.value!)
        .then((res) => { 
            if (res.login) {
              aloginToken.value = res.login;
            }
            getInfo().then((data)=>resolve(true)); 
          })
        .catch((error) => {
          reject(error);
        });
    });
  }

  let loopHandler:any = null;

  // 获取信息(用户昵称、头像、角色集合、权限集合)
  function getInfo() {
    return new Promise<IUser>((resolve, reject) => {
      getUserInfo().then((data) => {
          if (data==null) {
            return reject("Verification failed, please Login again.");
          }
          principal.value = data;
          showName.value = data.realName ? data.realName : data.userName;
          avatar.value = data.hasAvatar ? getMyAvatarURL()! : "avatar.gif";
          if (!isAdmin())
          loopHandler = setInterval(setUnreadMessageCount,1000 * 60);
          console.log(data,"userinfo");
          return resolve(data);
        }).catch((error) => {
          reject(error);
        });
    });
  }
  function isAdmin() {
    if (!principal.value) return false;
    const registerType = principal.value.registerType;
    return (1 & registerType) == 1
  }
  function isStaff() {
    if (!principal.value) return false;
    const registerType = principal.value.registerType;
    return (4 & registerType) == 4
  }
  function isCompany() {
    if (!principal.value) return false;
    const registerType = principal.value.registerType;
    return (2 & registerType) == 2
  }
  function isWechat() {
    if (!principal.value) return false;
    const registerType = principal.value.registerType;
    return (8 & registerType) == 8
  }
  /**
   * 修改用户自己的密码
   */
  function savePassword(data: IPasswordMap) {
    return new Promise<boolean>((resolve) => {
      savePasswordApi(data).then((res ) => resolve(res));
    });
  }

  // 注销
  function logout() {
    return new Promise<void>((resolve, reject) => {
      logoutApi()
        .then(() => {
          resetValue();
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // 重置
  function resetValue() {
    principal.value = null;
    messageCount.value = null;
    avatar.value = "";

    if (loopHandler) {
      clearInterval(loopHandler)
    }
    useAppStore().logout();
  }
  function setMessageCount(val:number) {
    messageCount.value = val;
    
  }
  function setUnreadMessageCount() {
     
    //console.log("get unreaded message count.")
    getUnreadMessageCountApi(principal.value.id!).then((data) => {
      setMessageCount(data);
    }).catch(err => console.error(err));
  }
  return {
    avatar,
    principal,
    username,
    showName,
    messageCount,
    setUnreadMessageCount,
    isAdmin,
    isCompany,
    isStaff,
    isWechat,
    login,
    autoLogin,
    getInfo,
    logout,
    resetValue,
    savePassword
  };
});

// 非setup
export function useUserStoreHook() {
  return useUserStore(store);
}
