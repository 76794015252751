import type { PageContent } from '@/api/types';
import request from "@/utils/request";
import type { IStaff,IOwner } from "./types";


export function listApi(queryParams: Object): Promise<PageContent> {
    return request({
        url: "/admin/staffs",
        method: 'get',
        params: queryParams
    });
}
export function listInOrgApi(orgId:number,queryParams: Object): Promise<PageContent> {
    return request({
        url: `/admin/orgs/${orgId}/staffs`,
        method: 'get',
        params: queryParams
    });
}
export function listOwnerApi(queryParams: Object): Promise<PageContent> {
    return request({
        url: "/admin/owners",
        method: 'get',
        params: queryParams
    });
}
export function listOwnerWithApi(names: string[]): Promise<IOwner[]> {
    return request({
        url: "/admin/owners/in",
        method: 'post',
        data: names
    });
}
export function listOwnerInOrgApi(orgId:number,queryParams: Object): Promise<PageContent> {
    return request({
        url: `/admin/orgs/${orgId}/owner`,
        method: 'get',
        params: queryParams
    });
}
export function countApi(queryParams: Object): Promise<number> {
    return request({
        url: "/admin/staffs-count",
        method: 'get',
        params: queryParams
    });
}
export function countInOrgApi(orgId:number,queryParams: Object): Promise<number> {
    return request({
        url: `/admin/orgs/${orgId}/staffs-count`,
        method: 'get',
        params: queryParams
    });
}
/**
 * @param data 
 * @returns id for data
 */
export function createOrUpdateApi(data: IStaff): Promise<number> {
    return request({
        url: "/admin/staffs",
        method: 'post',
        data: data
    });
}
export function getApi(id: number): Promise<IStaff> {
    return request({
        url: `/admin/staffs/${id}`,
        method: 'get',
    });
}
export function uploadPhotoApi(id: number,data:any) {
    return request({
        url: `/admin/staffs/${id}/photo`,
        method: 'post',
        transformRequest: [function(data, headers) {
            // 去除post请求默认的Content-Type
            delete headers['Content-Type']
            return data
          }],
        headers:  { enctype: 'multipart/form-data' },
        data: data,
    });
}
export function deleteApi(id: number): Promise<boolean> {
    return request({
        url: `/admin/staffs/${id}`,
        method: 'delete',
    });
}
