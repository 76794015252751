<script setup lang="ts">


import { IOwner } from "@/api/admin/staff/types"
import { listOwnerWithApi} from "@/api/admin/staff"



import {
  Search,
} from '@element-plus/icons-vue';


const props = defineProps({
  /**
   * 文件ID
   */
  modelValue: {
    type: String,
    default: ""
  },
  userNames: {
    type: String,
    default: ""
  },
  labelName: {
    type: String,
    default: "请选择"
  }

});


const emit = defineEmits(["update:modelValue"]);
const checkedUser = useVModel(props, "modelValue", emit);

const candidateUsers = ref<IOwner[]>([])
const staffs = ref<IOwner[] | null>(null)





onMounted(() => {
  getCandidateUsers(props.userNames)
});



function showStaffLabel(staff) {
  return staff.realName + "[" + staff.position.name + "]"
}



function getCandidateUsers(userNames) {
  if (!userNames || userNames == '') return;
  const names = userNames.split(",").map(s => s.trim());
  listOwnerWithApi(names).then(data => {
    if (data.length > 0) {
      staffs.value = data
      candidateUsers.value = staffs.value.filter(s=>s.userName==checkedUser.value)
    }

  })
};

///////////////////////candidateUsers


function selectCandidateUsers(staff) {
  if (staff) {
    checkedUser.value = staff.userName
  }
};




</script>

<template>

      <el-select v-model="candidateUsers" value-key="userName" filterable clearable default-first-option
        :placeholder="props.labelName"  @change="selectCandidateUsers"
        >
        <template #prefix>
          <el-icon>
            <Search />
          </el-icon>
        </template>
        <el-option v-for="item in staffs" :label="showStaffLabel(item)" :value="item" />
      </el-select>


</template>
