export default {
  Gateway: 'Gateway',
  'Exclusive Gateway': 'Exclusive Gateway',
  'Parallel Gateway': 'Parallel Gateway',
  'Inclusive Gateway': 'Inclusive Gateway',
  'Complex Gateway': 'Complex Gateway',
  'Event based Gateway': 'Event based Gateway',
  ExclusiveGateway: 'Exclusive Gateway',
  ParallelGateway: 'Parallel Gateway',
  InclusiveGateway: 'Inclusive Gateway',
  ComplexGateway: 'Complex Gateway',
  EventBasedGateway: 'Event based Gateway'
}
