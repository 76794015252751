import { IContact, IMessage } from "./components/types";
import MemoryCache from "./memory"

const CacheContactContainer = new MemoryCache();
const CacheMenuContainer = new MemoryCache();
const CacheMessageLoaded = new MemoryCache();
const CacheDraft = new MemoryCache();

let allMessages:Record<string,Array<IMessage>> ;
let allContacts:Array<IContact> = []
let currentUser:IContact, currentContact:IContact;
export function createMessage(message):IMessage {
    return {
      ...{
        id: generateUUID(),
        type: "text",
        status: "going",
        sendTime: new Date().getTime(),
      },
      ...message,
    };
  }
 function  _addMessage(data, contactId,) {
 
    if (!Array.isArray(data)) data = [data];
    allMessages[contactId] = allMessages[contactId] || [];
    allMessages[contactId].push(...data);
  }
  /**
   * 新增一条消息
   */
function   appendMessage(message:Partial<IMessage>, scrollToBottom = false) {
    let unread = 1;
    let messageList = allMessages[message.toContactId!];
    // 如果是自己的消息需要push，发送的消息不再增加未读条数
    if (message.type == 'event' || currentUser.id == message.fromUser?.id) unread = 0;
    if (messageList === undefined) {
      updateContact({
        id: message.toContactId,
        unread: unread,
        lastSendTime: message.sendTime,
        lastMessage: message,
      });
    } else {
      // 如果消息存在则不再添加
      let hasMsg = messageList.some(({id})=>id == message.id);
      if (hasMsg) return;
      _addMessage(message, message.toContactId);
      const _updateContact = {
        id: message.toContactId,
        unread,
        lastMessage: message,
        lastSendTime: message.sendTime
      };
      if (message.toContactId == currentContact.id) {
        if (scrollToBottom == true) {
        //   messageViewToBottom();
        }
        CacheDraft.remove(message.toContactId);
      } else {
        _updateContact.unread = unread;
      }
      updateContact(_updateContact);
    }
  }

function   _handleSend(text, chatArea) {
    const atUserList=chatArea.getCallUserList();
    // 将数组中的id提取出来
    const atUserIds=atUserList.map(item=>item.id);
    const message = _createMessage({ content: text,at:atUserIds});
    appendMessage(message, true);
    // _emitSend(message, () => {
    //   updateContact({
    //     id: message.toContactId,
    //     lastMessage: message),
    //     lastSendTime: message.sendTime,
    //   });
    //   CacheDraft.remove(message.toContactId);
    // });
  }
  /**
     * 删除一条聊天消息
     * @param messageId 消息 id
     * @param contactId 联系人 id
     */
 export function removeMessage(messageId) {
    const message = findMessage(messageId);
    if (!message) return false;
    const index = allMessages[message.toContactId].findIndex(
      ({ id }) => id == messageId,
    );
    allMessages[message.toContactId].splice(index, 1);
    return true;
  }
  /**
   * 修改聊天一条聊天消息
   * @param {Message} data 根据 data.id 查找聊天消息并覆盖传入的值
   * @param contactId 联系人 id
   */
 export function updateMessage(message) {
    if (!message.id) return false;
    let historyMessage = findMessage(message.id);
    if (!historyMessage) return false;
    historyMessage = Object.assign(historyMessage, message, {
      toContactId: historyMessage.toContactId,
    });
    return true;
  }

    /**
     * 设置联系人的草稿信息
     */
  function setDraft(cid, editorValue) {
    if (isEmpty(cid) || isEmpty(editorValue)) return false;
    const contact = findContact(cid);
    let lastMessage = contact?.lastMessage;
    if (isEmpty(contact)) return false;
    if (CacheDraft.has(cid)) {
      lastMessage = CacheDraft.get(cid)?.lastMessage;
    }
    CacheDraft.set(cid, {
      editorValue,
      lastMessage,
    });
    updateContact({
      id: cid,
      lastMessage: `<span style="color:red;">[草稿]</span><span> ${editorValue}</span>`,
    });
  }
  /**
   * 清空联系人草稿信息
   */
  function clearDraft(contactId) {
    const draft = CacheDraft.get(contactId);
    if (draft) {
      const currentContent = findContact(contactId)?.lastMessage?.content;
      if (
        currentContent?.indexOf('<span style="color:red;">[草稿]</span>') === 0
      ) {
        updateContact({
          id: contactId,
          lastMessage: draft?.lastMessage,
        });
      }
      CacheDraft.remove(contactId);
    }
  }

   
  /**
     * 初始化联系人数据
     * @param {Array<Contact>} data 联系人列表
     */
 export function initContacts(data) {
    allContacts = data;
    sortContacts();
  }
  /**
   * 使用 联系人的 index 值进行排序
   */
 function sortContacts() {
    allContacts.sort((a, b) => {
      if (!a.index || !b.index) return 0;
      return a.index.localeCompare(b.index);
    });
  }
 export function appendContact(contact) {
    if (isEmpty(contact.id) || isEmpty(contact.displayName)) {
      console.error("id | displayName cant be empty");
      return false;
    }
    if (hasContact(contact.id)) return true;
    allContacts.push(
      Object.assign(
        {
          id: "",
          displayName: "",
          avatar: "",
          index: "",
          unread: 0,
          lastSendTime: "",
          lastMessage: "",
        },
        contact,
      ),
    );
    return true;
  }
 export function removeContact(id) {
    const index = findContactIndexById(id);
    if (index === -1) return false;
    allContacts.splice(index, 1);
    CacheDraft.remove(id);
    CacheMessageLoaded.remove(id);
    return true;
  }
  /**
   * 修改联系人数据
   * @param {Contact} data 修改的数据，根据 Contact.id 查找联系人并覆盖传入的值
   */
  function updateContact(data) {
    const contactId = data.id;
    delete data.id;

    const index = findContactIndexById(contactId);
    if (index !== -1) {
      const { unread } = data;
      if (isString(unread)) {
        if (unread.indexOf("+") === 0 || unread.indexOf("-") === 0) {
          data.unread =
            parseInt(unread) + allContacts[index].unread;
        }
      }
      Object.assign(allContacts, index, {
        ...allContacts[index],
        ...data,
      });
    }
  }
  /**
   * 根据 id 查找联系人的索引
   * @param contactId 联系人 id
   * @return {Number} 联系人索引，未找到返回 -1
   */
 function findContactIndexById(contactId) {
    return allContacts.findIndex(item => item.id == contactId);
  }
  /**
   * 根据 id 查找判断是否存在联系人
   * @param contactId 联系人 id
   * @return {Boolean}
   */
  function hasContact(contactId) {
    return findContactIndexById(contactId) !== -1;
  }
  function findMessage(messageId) {
    for (const key in allMessages) {
      const message = allMessages[key].find(({ id }) => id == messageId);
      if (message) return message;
    }
  }
  function  findContact(contactId) {
    return allContacts.find(({ id }) => id == contactId);
  }

export function generateUUID() {
    var d = new Date().getTime();
    if (window.performance && typeof window.performance.now === "function") {
    d += performance.now(); //use high-precision timer if available
    }
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
    c,
    ) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
}

export function isPlainObject(obj) {
    return Object.prototype.toString.call(obj) === "[object Object]";
  }
  export function isString(str) {
    return typeof str == "string";
  }
  export function isToday(time) {
    return new Date().getTime() - time < 86400000;
  }
  export function isEmpty(obj) {
    if (!obj) return true;
    if (Array.isArray(obj) && obj.length == 0) return true;
    if (isPlainObject(obj) && Object.values(obj).length == 0) return true;
    return false;
  }
  export function isUrl(str) {
    const reg =
      "^((https|http|ftp|rtsp|mms)?://)" +
      "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?" + //ftp的user@
      "(([0-9]{1,3}.){3}[0-9]{1,3}" + // IP形式的URL- 199.194.52.184
      "|" + // 允许IP和DOMAIN（域名）
      "([0-9a-z_!~*'()-]+.)*" + // 域名- www.
      "([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]." + // 二级域名
      "[a-z]{2,6})" + // first level domain- .com or .museum
      "(:[0-9]{1,4})?" + // 端口- :80
      "((/?)|" + // 如果没有文件名，则不需要斜杠
      "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$";
    return new RegExp(reg).test(str) ? true : false;
  }
  
  export function isFunction(val) {
    return val && typeof val === "function";
  }
  
  export function isEng(val) {
    return /^[A-Za-z]+$/.test(val);
  }
  