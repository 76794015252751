<script setup lang="ts">
import { ElConfigProvider } from 'element-plus';
import {messages} from "@/lang";
import { useAppStore } from "@/store";

import { SizeEnum } from "@/enums/SizeEnum";

const appStore = useAppStore();

const size = computed(() => appStore.size as SizeEnum);
</script>

<template>
  <el-config-provider :locale="messages[appStore.language]" :size="size">
    <router-view />
  </el-config-provider>
</template>

<style lang="scss">


</style>