/**
 * api/auth 为鉴权模块
 */
import request from '@/utils/request';
import type { ICheckedApi, IOnlineUser, LicenseResult, LoginData } from './types';
import type { IUser, Principal } from '../admin/user/types';
import { useAppStore } from "@/store/modules/app";
import { PageContent } from '../types';

export function checkCaptcha(): Promise<Boolean> {
    return request({
      url: '/captcha/image/enabled',
      method: 'get'
    });
}
/**
 * 登录API
 *
 * @param data {LoginData}
 * @returns
 */
export function loginApi(data: LoginData): Promise<any> {
    let formData = "username=" + data.username.trim() + "&password=" + data.password.trim();
    
    if (data.captchaVerification) {
        formData += "&captchaVerification="+data.captchaVerification;
    }
    return request({
        url: data.captchaVerification?`/login?captchaVerification=${data.captchaVerification}`:`/login`,
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: encodeURI(formData)
    });
}
/**
 * App 自动登录
 * 登录失败 返回 status = 401
 * @param data
 * @returns 
 */
export function aloginApi(data: string): Promise<any> {
    return request({
        url: '/alogin',
        method: 'post',
        headers: { global: 'refresh', 'Authorization': 'ALogin ' + data },
    });
}

/**
 * 注销API
 */
export function logoutApi(): Promise<void> {
    const session = useAppStore().getSession();
    if (null != session) {
        return request({
            url: '/logout',
            method: 'post'
        });
    } else {
        return Promise.resolve();
    }
}

/**
 * 登录成功后获取用户信息
 * 返回Passport 和 Staff 合并后的信息
 */
export function getUserInfo(): Promise<Principal> {
    return request({
      url: '/p',
      method: 'get'
    });
}
export function refreshToken(): Promise<Boolean> {
    return request({
      url: '/ping',
      method: 'head',
      headers:  { global: 'refresh' },
    });
}
  
/**
 * 查看版权信息
 */
export function checkLicense(): Promise<LicenseResult> {
    return request({
        url: '/license',
        method: 'get'
    });
}
export function checkApi(api: ICheckedApi): Promise<Boolean> {
    return request({
        url: '/admin/auths/checkapi',
        method: 'get',
        params: api
    });
}
export function checkRoleApi(key:string): Promise<Boolean> {
    return request({
        url: `/admin/auths/checkrole/${key}`,
        method: 'get',
    });
}
export function listOnlineApi(queryParams: Object): Promise<PageContent> {
    return request({
        url: "/admin/users/online",
        method: 'get',
        params: queryParams
    });
}
export function getOnlineApi(userName: string): Promise<IOnlineUser> {
    return request({
        url: `/admin/users/online/${userName}`,
        method: 'get',
    });
}