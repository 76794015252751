import type { PageContent } from '@/api/types';
import request from '@/utils/request';

export function getMessageListApi(queryParams:Object): Promise<PageContent> {
    return request({
        url: "/users/messages",
        method: 'get',
        params: queryParams
    });
}

export function getUnreadMessageCountApi(id:number): Promise<number> {
    return request({
        url: `/messages/users/${id}/unread/count`,
        method: 'get',
        headers:  { global: 'nosession' },
    });
}

export function clearMessageUnreadApi(): Promise<boolean> {
    return request({
        url: "/users/messages/unread/clear",
        method: 'post',
    });
}

export function setMessageReadedApi(ids:number[]): Promise<boolean> {
    return request({
        url: "/users/messages/readed",
        method: 'post',
        data: ids
    });
}


export function deleteMessageApi(id:number): Promise<boolean> {
    return request({
        url: `/users/messages/${id}`,
        method: 'delete',
    });
}