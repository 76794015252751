export default {
    erp: {
        label: {
            Category:"类别",
            Warehouse:"仓库",
            Supplier:"供应商",
            Shop:"门店"
        }
    },
    
}