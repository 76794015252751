export default {

    6001:"{a0}库存不足, 差额为:{a1}",
    6002:"可预售数量小于已预售数量{a0}",
    6003:"产品库存校验异常:{a0},{a1}",
    6004:"产品申请异常! 与订单不匹配！{a0}",
    6005:"产品申请异常! 数量不正确！{a0}:{a1}",
    6006:"产品预售库存不足,{a0}:{a1}",
    6007:"未审核的采购产品不能记入库存,{a0}",
    6008:"采购预增库存不能小于0,{a0}:{a1}",
    6009:"库存不一致,{a0}",
    6010:"未审核的销售订单不能记入库存,{a0}",
    6011:"审核状态异常,{a0}:{a1}",
    6012:"出库数量异常,{a0}:{a1}",
    6013:"产品单价不能小于0,{a0}:{a1}",
    6014:"可用库存不足,{a0}:{a1}",
    6015:"已出库数量不足,{a0}:{a1}<{a2}",
}