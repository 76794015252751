export default {
  tagsView: {
    refresh: "刷新",
    close: "关闭",
    closeOthers: "关闭其他",
    closeLeft: "关闭左侧",
    closeRight: "关闭右侧",
    closeAll: "全部关闭",
  },
  // 登录页面国际化
  login: {
    username: "用户名",
    password: "密码",
    login: "登 录",
    captchaCode: "验证码",
    capsLock: "大写锁定已打开",
    rememberMe: "记住我",
    forgetPassword: "忘记密码",
    verifyCode: '验证码',
    register: '注册',
    message: {
      username: {
        required: "请输入用户名",
      },
      password: {
        required: "请输入密码",
        min: "密码不能少于6位",
      },
      captchaCode: {
        required: "请输入验证码",
      },
    },
    otherLoginMethods: "其他登录方式",
  },
  // 导航栏国际化
  navbar: {
    dashboard: "首页",
    logout: "退出",
    document: "项目文档",
    gitee: "项目地址",
    profile: "个人中心",
    settings: '设置'
  },
  sizeSelect: {
    tooltip: "布局大小",
    default: "默认",
    large: "大型",
    small: "小型",
    message: {
      success: "切换布局大小成功！",
    },
  },
  langSelect: {
    message: {
      success: "切换语言成功！",
    },
  },
  settings: {
    project: "项目配置",
    theme: "主题设置",
    interface: "界面设置",
    navigation: "导航设置",
    themeColor: "主题颜色",
    tagsView: "开启 Tags-View",
    fixedHeader: "固定 Header",
    sidebarLogo: "侧边栏 Logo",
    watermark: "开启水印",
  },
  
 
  datagrid: {
    loading: "加载中...",
    label: {
      Admin: "管理员",
      Name: "名称",
      ShortName: "简称",
      Title: "标题",
      Content: "内容",
      Description: "描述",
      Username: "用户名",
      Avatar: "头像",
      Mobile: "手机",
      Email: "邮箱",
      Creation: "创建时间",
      Modification: "更新",
      Expiration: "过期时间",
      Status: "状态",
      Action: "操作",
      Search: "搜索",
      Selection: "请选择",
      OK: "确定",
      Save: "保存",
      Cancel: "取消",
      Back: "返回",
      Sort: "排序",
      Reset: "重置",
      Version: "版本",
      Reload: "加载",
      Auth: "权限",
      Attachment: "附件",
      Submit: "提交",
      picker: {
        date: {
          last_week: "上周",
          last_month: "上月",
          last_3_month: "近3月"
        }
      }
    },
    title: {
      warning: "提示"
    },
    placeholder: {
      Selection: "请选择",
      date_picker: "请选择日期",
      search: "在这里输入",
      input: "请输入"

    },
    message: {
      create: {
        success: "新增成功",
        fail: "新增失败",
      },
      update: {
        success: "更新成功",
        fail: "更新失败",
      },
      delete: {
        confirm: "确认删除?",
        type: "警告",
        canceled: "已取消删除",
        success: "删除成功",
        fail: "删除失败",
        cascades1: "级联删除[",
        cascades2: "] 是否继续?",
        warning: "此操作将永久删除, 是否继续?"
      },
      export: {
        confirm: "确认导出?",
        type: "提示",
        canceled: "已取消导出",
        success: "导出成功",
        fail: "导出失败",
      },
      rule: {
        number: "无效数字",
        name: "请输入名字",
        short: "请输入简称",
        required: "请在此输入",
        letters: "请用英文字母",
        format: "格式错误"
      },
      upload: {
        format: "文件类型不正确",
        limit10M: "上传文件大小不能超过10M",
        success: "上传成功",
        fail: "上传失败"
      }



    },

    dialog: {
      title: {
        Create: "新增",
        Update: "编辑",
        Menu: "菜单",
        Role: "角色",
        Business: "业务数据",
        Code: "代码展示"
      }
    },
    row: {
      No: "序号",
      ID: "ID",

      Create: "新增",
      Update: "编辑",
      Delete: "删除",
    },

    page: {

    },
    enum: {
      VALID: "有效",
      INVALID: "无效",
      YES: "是",
      NO: "否"
    },
    treenode: {
      moveTo: "移动到",
      copyTo: "复制到",
      move: "移动",
      copy: "复制",
      message: "下<br>此操作将重新排序,更新ID, 取消请关闭?",
      cancel: "放弃移动操作",
      label: {
        Parent: "上级",
        Short: "简写",
        SubModule: "子模块"
       
      }
    }
  },

  template: {
    datagrid: {
      placeholder: {

      },
      label: {

      },
      title: {

      },
      message: {

      },
      dialog: {

      },
      row: {

      }
    },
  }

};
