export interface Session {
    readonly id: string; //==access-token

    isExpired(): boolean;
    updateTime(): void;
    getLastAccessed(): number;

}
export const DURATION: number = 600;//600秒
export function createSession(token: string, accessed?: number): Session {

    let lastAccessedTime: number = accessed != undefined ? accessed : Date.now().valueOf();//毫秒
    
    const session: Session = {
        id: token,
        updateTime: function () {
            lastAccessedTime = Date.now().valueOf();
        },
        getLastAccessed: function () { return lastAccessedTime },
        isExpired: function () {
            return false;
           // const timeout = lastAccessedTime + DURATION * 1000;
           // return timeout <= Date.now().valueOf()
        }
    }
    return session;
}