export default {
  title: '属性面板',
  general: '常规信息',
  id: 'ID',
  name: '名称',
  version: '版本',
  executable: '可执行',

  documentationSettings: '描述',
  documentationBody: '内容',

  startInitiator: '发起人变量',
  initiator: '发起人',

  executionJob: '执行作业',
  taskPriority: '任务优先级',
  retryTimeCycle: '重试周期',

  asyncContinuations: '异步控制',
  asyncBefore: '异步前',
  asyncAfter: '异步后',
  asyncExclusive: '异步优先',

  conditionalSettings: '条件设置',
  variableName: '变量名称',
  variableEvents: '变量事件',
  conditionType: '条件类型',
  conditionExpression: '条件内容',

  scriptType: '脚本类型',
  scriptLanguage: '脚本语言',
  scriptBody: '脚本内容',
  scriptResource: '资源地址',
  scriptFormat: '脚本格式',

  executionListeners: '执行监听器',
  addExecutionListener: '添加执行监听',
  executionListenerEventType: '事件类型',
  executionListenerType: '监听器类型',

  extensionProperties: '扩展属性',
  addExtensionProperties: '添加扩展属性',
  propertyName: '属性名称',
  propertyValue: '属性值',

  javaClass: 'Java类',
  expression: '条件表达式',
  delegateExpression: '代理条件表达式',

  index: '序号',
  operations: '操作',
  edit: '编辑',
  remove: '移除',
  confirm: '确认',

  formKey: '表单类型',

  userAssignments: "指定人员",
  assignee: "审批人",
  candidateUsers: "候选人",
  candidateGroups: "候选组",
  dueDate: "期望时间",
  followUpDate:"跟进时间",
  priority: "优先级"
}
