// 路由

export default {
  Admins: "系统角色",
  SuperAdmin: "超级管理员",
  Actuators: "系统指标",
  Systems: "系统管理",
  Organizations: "组织机构",
  Login: "登录",
  Businesses: "业务角色",
  Departments: "部门管理",
  
}
