import { IMenu } from "@/api/admin/menu/types"
import { RouteRecordRaw } from "vue-router";

import { useUserStore } from "@/store/modules/user";


const Layout = () => import("@/layout/index.vue"/* @vite-ignore */);

const Designer = () => import("@/bpmnjs/App.js"/* @vite-ignore */);;

let modules = import.meta.glob("../views/**/*.vue");

let subViews = import.meta.glob("../../subs/**/*.vue");

function loadBaseView(view: string) {

    if (view && view.startsWith("/")) {
        view = view.substring(1);
    }
    return modules[`../views/${view}.vue`];
}
function loadView(sub: string | undefined, view: string) {

    if (view && view.startsWith("/")) {
        view = view.substring(1);
    }
    const subModule = subViews[`../../subs/${sub}/views/${view}.vue`];
    if (subModule) return subModule;

    return modules[`../views/${view}.vue`];
}
function loadHomeView(view: string) {

    if (view && view.startsWith("/")) {
        view = view.substring(1);
    }
    const subModule = subViews[`../../subs/home/${view}.vue`];
    if (subModule) return subModule;

    return modules[`../views/${view}.vue`];
}


function decideComponent(subModule: string | undefined, menu: IMenu) {

    if (menu.url)
        return loadView(subModule, menu.url);
    else {
        if (!menu.parent)
        return Layout;
    }
    return undefined

}

export const constantRoutes = function () {
    let routes: RouteRecordRaw[] = [];

    let register: RouteRecordRaw = {
        path: '/register',
        name: 'register',
        component: loadHomeView("login/register"),
        meta: { title: 'Register', icon: 'login', hidden: true, keepAlive: true }
    };
    let login: RouteRecordRaw = {
        path: '/login',
        name: 'login',
        component: loadHomeView("login/index"),
        meta: { title: 'Login', icon: 'login', hidden: true, keepAlive: true }
    };
    routes.unshift(login);
    routes.unshift(register);
    routes.unshift({
        path: '/',
        name: 'first',
        component: Layout,
        redirect: "i",
        children: [
            {
                path: 'i',
                name: 'dashboard',
                component: loadHomeView("dashboard/index"),
                meta: { title: 'Dashboard', icon: 'dashboard', keepAlive: true }
            }
        ]
    });
    if (!useUserStore().isAdmin())
        routes.push({
            path: '/',
            name: 'message_center',
            component: Layout,
            children: [
                {
                    path: 'm',
                    name: 'message',
                    component: loadHomeView("user/Message"),
                    meta: { title: 'Messages', icon: 'message', keepAlive: true }
                }
            ]
        });
    /**
     * bpmnjs designer
     */
    routes.push({
        path: '/',
        name: "designer_layout",
        component: Layout,
        children: [
            {
                path: 'design/:id',
                name: 'designer',
                component: Designer,
                meta: { title: 'Designers', icon: 'workflow', hidden: true, keepAlive: true }
            }
        ]

    });
    routes.push({
        path: '/',
        name: "form-layout",
        component: Layout,
        children: [
            {
                path: 'form-design',
                name: 'form-designer',
                component: loadBaseView("activiti/form/designer"),
                meta: { title: 'Forms', icon: 'workflow', hidden: true, keepAlive: true }
            }
        ]

    });


    routes.push({ path: '/:catchAll(.*)', redirect: "/i" })
    return routes;
}
export const loginRoutes = function () {
    let routes: RouteRecordRaw[] = [];
    let register: RouteRecordRaw = {
        path: '/register',
        name: 'register',
        component: loadHomeView("login/register"),
        meta: { title: 'Register', icon: 'login', hidden: true, keepAlive: true }
    };
    let login: RouteRecordRaw = {
        path: '/login',
        name: 'login',
        component: loadHomeView("login/index"),
        meta: { title: 'Login', icon: 'login', hidden: true, keepAlive: true }
    };
    routes.unshift(login);
    routes.unshift(register);


    routes.push({ path: '/:catchAll(.*)', redirect: "/login" })
    return routes;
}

export const RoutesBuilder = function (menus: IMenu[], onlySelected: boolean) {
    let subModule:string|undefined = undefined
    let targetMenus: IMenu[] = [];
    for (let i = 0; i < menus.length; i++) {
        if (menus[i].hidden ) {
            if (menus[i].menus)
                targetMenus = targetMenus.concat(menus[i].menus);
            subModule = menus[i].shortName;
        } else {
            targetMenus.push(menus[i]);
        }
    }
    let routes: RouteRecordRaw[] = generateRoutes(subModule, targetMenus, onlySelected);
    //console.log("routes", menus, routes)
    return routes;
}

function generateRoutes(subModule: string | undefined, menu: IMenu[], onlySelected: boolean): RouteRecordRaw[];
function generateRoutes(subModule: string | undefined, menu: IMenu, onlySelected: boolean): RouteRecordRaw;
function generateRoutes(subModule: string | undefined, menu: IMenu | IMenu[], onlySelected: boolean): RouteRecordRaw | RouteRecordRaw[] {
    if (!menu) return []
    if (menu instanceof Array) {
        let routes: RouteRecordRaw[] = []
        for (let i = 0; i < menu.length; i++) {
            if (!(onlySelected && typeof menu[i].selected != undefined && (!menu[i].selected))) {
                routes.push(generateRoutes(subModule, menu[i], onlySelected))
            }
        }
        return routes;
    } else {
        let sn = "menu" + menu.menuid;
        // if (typeof menu.shortName != 'undefined' && menu.shortName != 'null' && menu.shortName != '')
        //     sn = menu.shortName;
        let p = "i" + menu.menuid
        if (typeof menu.path != 'undefined' && menu.path != 'null' && menu.path != '')
            p = menu.path;
        let route: RouteRecordRaw =
        {
            path: p,
            component: decideComponent(subModule, menu),
            name: sn,
            meta: {
                title: menu.menuname,
                icon: menu.icon,
                hidden: menu.hidden,
                keepAlive: true
            },
            redirect: "",
            children: []
        }

        //console.log(route)
        if (menu.menus) {
            route.path = "/"
            route.redirect = ""//"i"+menu.menus[0].menuid
            route.children = [];
            if (menu.shortName) {
                subModule = menu.shortName
            }
            for (let i = 0; i < menu.menus.length; i++) {
                /**
                 * 过滤菜单
                 * 是否选择，被授权
                 */
                menu.menus[i].parent = menu
                if (!(onlySelected && typeof menu.menus[i].selected != undefined && (!menu.menus[i].selected)))
                    route.children.push(generateRoutes(subModule, menu.menus[i], onlySelected))

            }

        } else if (!menu.parent) {
            return {
                path: '/',
                name: "parent" + menu.menuid,
                component: Layout,
                children: [
                    route
                ]
            }


        }
        return route
    }
}
