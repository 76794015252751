export default {
  user: {
    placeholder: {
      search: "Username/Mobile",
    },
    datagrid: {
      label: {
        Repassword: "Repassword",
        Password: "Password",
        BusinessObjects: "BusinessObjects",
        DataScope: "DataScope",
        BusinessField: "BusinessField"
      },
      title: {

      },
      message: {
        create: {
          success: "Create user succeed",
          fail: "create user failed",
        },
        update: {
          success: "Update user success",
          fail: "Update user failed",
        },
        delete: {
          confirm: "Confirm to delete user?",
          type: "Warning",
          canceled: "Cancel delete",
          success: "Deletion Succeed",
          fail: "Deletion Failed",
        },
        password: {
          length: "must have over 6 letters",
          required: "enter password heer",
          repassword: "reenter password heer",
          consistency: "passwords are not consistent",
          reset: {
            confirm: "Reset Password？Can not restore it!",
            canceled: "Reset Password Canceled",
            success: "Reset Password Succeed",
            fail: "Reset Password Failed",
          },
          newpassword: {
            success: "Password Modification Succeed",
            fail: "Password Modification Failed",
          }
        },
        mobile: {
          rule: "invalid mobile",
          unique: "mobile exists",
        },
        username: {
          required: "username is required",
          rule: "invalid",
          chinese: "chinese characters",
          unique: "username exists",
        }
      },
      row: {


      },
      button: {
        password: "Password",
        menu: "Menu",
        role: "Role",
        data: "Business",
      },
      dialog: {
        title: {
          create: "Create User",
          update: "Update User",
          password: "Password",
          menu: "Menu",
          role: "Role",
          business: "Business",
          data: "Business",
        }
      },


    }
  },
  staff: {
    datagrid: {
      label: {
        RealName:"Name",
        Company:"Company",
        Organization:"Organization",
        Position:"Position"
      },
      title: {

      },
      message: {
        realName: {
          required: "real name is required"
        },
        organization: {
          required: "organization is required"
        },
        position: {
          required: "position is required"
        }
      }
    }
  },
  
  organization: {
    datagrid: {
      label: {

      },
      dialog: {
        title: {
          create: "Create Organization",
          update: "Update Organization",
        }
      },
    }
  },
  position: {
    datagrid: {
      label: {
        Organization: "Organization"
      },
      dialog: {
        title: {
          create: "Create Position ",
          update: "Update Position",
          menu: "Menu",
          role: "Role",
          business: "Business",
          data: "Business",
        }
      },
    }
  },
  object: {
    placeholder: {
      search: "business object name",
    },
    datagrid: {
      label: {
        ClassName:"ClassName",
        Rules: "Rules",
        Company: "Company",
        Organization:"Organization",
        User:"User",
        Field:"Field",
        BusinessObjects: "BusinessObjects",
        DataScope: "DataScope",
        BusinessField: "BusinessField"
      },
      title: {
        Fields: "Fields"
      },
      message: {
        create: {
          success: "Creation Succeed",
          fail: "Creation Failed",
        },
        update: {
          success: "Update Succeed",
          fail: "Update Failed",
        },
        delete: {
          confirm: "Deletion Confirmed?",
          type: "Warning",
          canceled: "Deletion Canceled",
          success: "Deletion Succeed",
          fail: "Deletion Failed",
        }

      },

      username: {
        required: "Username is required",
        rule: "invalid",
        chinese: "chinese characters are not supported",
        unique: "Username exists",
      }
    },
    row: {


    },
    button: {
      password: "Password",
      menu: "Menu",
      role: "Role",
      data: "Business",
    },
    dialog: {
      title: {
        create: "Create Business Object",
        update: "Update Business Object",

        business: "Business",
        data: "Business",
      }
    },


  },
  url: {
    placeholder: {
      search: "URL,Group",
    },
    datagrid: {
      label: {
        Group: "Group",
        Action:"Actions"
        
      },
      message: {
        name: "Name is required",
        order: "Order is required",
        url: "URL is required",
        delete: {
          confirm: "Deletion Confirmed?",
          type: "Warning",
          canceled: "Deletion Canceled",
          success: "Deletion Succeed",
          fail: "Deletion Failed",
        },
      },
      title: {

      },
      button: {
        auths:"check auths"
      }
    }
  },
  webmodule: {
    placeholder: {
      search: "Group Name",
    },
    datagrid: {
      label: {
        Parent: "Upper",

      },
      message: {
        delete: {
          confirm: "Deletion Confirmed?",
          type: "Warning",
          canceled: "Deletion Canceled",
          success: "Deletion Succeed",
          fail: "Deletion Failed",
        },
      },
      title: {
        
      },
      button: {
        addurl:"Add URL"
      }
    }
  },
  menu :{
    datagrid:{
      placeholder: {
        icon: "pick an icon"
      },
      label:{
        Hide:"Hide",
        Icon:"Icon",
        Target: {
          label:"Target",
          default_:"Default",
          new_: "New",
          parent_:"Parent"
        },
        Absolute:"Absolute",


      }
    }
  },
  server: {
    config: {
      role: {
        business: "Business",
        system:"System"
      },
      menu: {
        business: "Business",
        system:"System"
      },
      url: {
        rule: "URL Check"
      },
      password: {
        provide:"Password"
      },
      log: {
        threshhold:"Log"
      },
      erp: {
        audit: {
          rule: {
            salesorder:"Audit Sales",
            purchaseorder:"Audit Purchase",
            transferorder:"Audit Transfer",
            inboundbatch:"Audit Inbound",
            outboundbatch:"Audit Outbound",
          }
        }
      }
    }
  },
  role: {
    datagrid: {
      button: {
        url: "URL"
      },
      dialog: {
        title: {
          url:"URL Perm"
        }
      },
      transfer: {
        title: {
          all:"All",
          selected:"Selected"
        }
      }
    }
  },
  log: {
    placeholder: {
      type:"enter category heer",
      range: {
        separator: "to",
        start:"Begin",
        end:"End"
      }
    },
    label:{
      type: "Category",
      
    }
    
  }


};
