export default {
  Dashboard: '仪表盘',
  Login: "登录 ",
  Messages: "消息板",
  Navigator: "功能导航",
  Admins: "超级管理",
  Passwords: "修改密码",
  Auths: "授权管理",
  Users: "用户管理",
  Menus: "菜单管理",
  Roles: "角色管理",
  Urls: "URL管理",
  UrlModules: "URL模块管理",
  ActionLogs: "操作日志",
  AuthLogs: "授权日志",
  Loggers: "日志级别",
  Configs: "配置管理",
  SystemConfig: "系统参数",
  BusinessConfig: "业务参数",
  SystemInfo: "系统信息",
  Dictionary: "数据字典",
  TagWords: "标签管理",
  Cities: "城市管理",
  RemoteAPI: "远程API",
  Modules: "系统模块",
  Departments: "部门管理",
  'Org&Pos': "部门&职务",
  Positions: "部门职务树",
  Staffs: "员工管理",
  Objects: "业务对象",
  Testers: "测试用户",
  Businesses: "业务管理",
  Tools: "系统工具",
  ApiDocs: "API文档",
  H2: "H2数据库",
  Onlines: "在线用户",
  ArticleManagement: "文章管理",
  ArticleCategories: "文章分类",
  Articles: "文章列表",
  Any: "用户菜单",

}