export default {

  company: {
    placeholder: {
      search: "请输入公司名称"
    },
    datagrid: {
      label: {
        CompanyName: "公司名称",
        Telephone: "电话",
        Address: "地址",
        Contact: "联系人",
        ContactPhone: "联系人电话",
        CompanyNo: "云ID",
        Status: "账户状态",
        Info: "基本信息",
        Website: "网址",
        Fax: "传真",
        Logo: "图片",
        License: "营业执照",
        AccountInfo: "账户信息",
        Industry: "经营范围",
        Account1: "账户1",
        Account2: "账户2",
        Bank1: "开户行1",
        Bank2: "开户行2",
        TaxNumber: "税号",
        UserName: "用户名"

      },
      message: {
        account: {
          not: "未创建",
          expired: "已过期",
          unnotified: "未通知",
          normal: "正常",
          expiration: "账户到期时间"
        },
        upload: {
          format: "只能上传jpg,png"
        }

      },
      button: {
        account: "账户",
        upload: "上传"
      },
      title: {
        dialog: "公司管理员账号"
      },
    },
  },

}