export default {
  tagsView: {
    refresh: "Refresh",
    close: "Close",
    closeOthers: "Close Others",
    closeLeft: "Close Left",
    closeRight: "Close Right",
    closeAll: "Close All",
  },
  // 路由

  role: {
    Admins: "Admins",
    SuperAdmin: "Super",
    Actuators: "Actuators",
    Systems: "Systems",
    Organizations: "Organizations",
    Login: "Login",
    Businesses: "Businesses",
    Departments: "Departments",
  },
  route: {
    Dashboard: 'Dashboard', 
    Login: "Login",
    Messages: "Messages",
    Navigator: "Navigator",
    Admins: "Admins",
    Passwords: "Passwords",
    Auths: "Auths",
    Users: "Users",
    Menus: "Menus",
    Roles: "Roles",
    Urls: "Urls",
    UrlModules: "UrlModules",
    ActionLogs: "ActionLogs",
    AuthLogs: "AuthLogs",
    Configs: "Configs",
    SystemConfig: "SystemConfigs",
    BusinessConfig: "BusinessConfigs",
    Dictionary: "Dictionay",
    TagWords: "TagWords",
    Cities: "Cities",
    RemoteAPI: "RemoteAPI",
    Modules: "Modules",
    Organizations: "Organizations",
    Companies: "Companies",
    Departments: "Departments",
    Positions: "Positions",
    Staffs: "Staffs",
    Objects: "Objects",
    Testers: "Testers",
    Businesses: "Businesses",
    Activities: "Workflows",
    Definitions: "Definitions",
    Instances: "Instances",
    Tasks: "Tasks",
    Designers: "Designers",
    Forms: "Forms",
    OAForms: "OAForms",
    StaffForms: "StaffForms",
    MyForms: "MyForms",
    Todos: "Todos",
    My: "My",
    Waitings: "Waitings",
    Claims: "Claims",
    Histories: "Histories",
    Tools: "Tools",
    ApiDocs: "API-Docs",
    H2: "H2 DB"
  },
  // 登录页面
  login: {
    title: 'Aries IT System',
    username: 'Username',
    password: 'Password',
    login: 'Login',
    capsLock: 'Caps lock is On',
    verifyCode: 'VerifyCode'
  },
  // 导航栏
  navbar: {
    dashboard: 'Dashboard',
    logout: 'Logout',
    document: 'Document',
    gitee: 'Gitee',
    settings: 'Settings'
  },
  datagrid: {
    loading: "Loading...",
    label: {
      Admin: "Admin",
      Name: "Name",
      ShortName:"Short",
      Title: "Title",
      Content: "Content",
      Description:"Description",
      Username: "Username",
      Mobile: "Mobile",
      Email: "Email",
      Creation: "Creation",
      Modification: "Modification",
      Expiration: "Expiration",
      Status: "Status",
      Action: "Actions",
      Search: "Search",
      Selection: "Selection",
      OK: "OK",
      Save: "Save",
      Cancel: "Cancel",
      Back: "Back",
      Sort: "Sort",
      Reset:"Reset",
      Version:"Version",
      Attachment: "Attachment",
      Reload:"Reload",
      Auth: "Auth",
      Submit: "Submit",
      picker: {
        date: {
          last_week:"last_week",
          last_month:"last_month",
          last_3_month:"last_3_month"
        }
      }
    },
    title: {
      warning: "Warning"
    },
    placeholder: {
      Selection: "Selection",
      date_picker: "Pick date",
      search:"enter heer",
      input: "input heer"

    },
    message: {
      create: {
        success: "Create Success",
        fail: "Create Fail",
      },
      update: {
        success: "Update Success",
        fail: "Update Fail",
      },
      delete: {
        confirm: "Confirm Deletion?",
        type: "Warning",
        canceled: "Deletion Canceled",
        success: "Deletion Success",
        fail: "Deletion Fail",
        cascades1: "Cascading Deletion[",
        cascades2: "] Continue?",
        warning: "Will delete permanantly, continue?"
      },
      rule: {
        number: "invalid",
        name: "name is required",
        short:"short is required",
        required: "enter heer",
        letters:"letters only",
        format: "format error"
      },
      upload: {
        format:"only accept formats: jpg,png",
        limit10M:"exceeding size of 10M",
        success: "upload success",
        fail: "upload fail"
      }
      


    },

    dialog: {
      title:{
        Create: "Create",
        Update: "Update",
        Menu: "Menu",
        Role: "Role",
        Business: "Business",
        Code: "Code"
      }
    },
    row: {
      No: "No",
      ID: "ID",

      Create: "Create",
      Update: "Update",
      Delete: "Delete",
    },

    page: {

    },
    enum: {
      VALID: "VALID",
      INVALID: "INVALID",
      YES: "YES",
      NO: "NO"
    },
    treenode: {
      moveTo: "moveTo",
      copyTo: "copyTo",
      move: "move",
      copy: "copy",
      message: "<br>will reorder, close to cancel?",
      cancel: "abort moving",
      label: {
        Parent: "Upper",
        Short: "Short",

      }
    }
  },
};
