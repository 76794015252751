export default {

  company: {
    placeholder: {
      search: "Company name"
    },
    datagrid: {
      label: {
        CompanyName: "Company",
        Telephone: "Telephone",
        Address: "Address",
        Contact: "Contact",
        ContactPhone: "ContactPhone",
        CompanyNo: "CompanyNo",
        Status: "Status",
        Info: "Info",
        Website: "Website",
        Fax: "Fax",
        Logo: "Logo",
        License: "License",
        AccountInfo: "AccountInfo",
        Industry: "Industry",
        Account1: "Account1",
        Account2: "Account2",
        Bank1: "Bank1",
        Bank2: "Bank2",
        TaxNumber: "TaxNumber",
        UserName: "UserName"

      },
      message: {
        account: {
          not: "Not Created",
          expired: "Expired",
          unnotified: "Unnotified",
          normal: "Normal",
          expiration: "Expiration"
        },
        upload: {
          format: "only supports jpg,png"
        }

      },
      button: {
        account: "User",
        upload: "Upload"
      },
      title: {},
    },
  },
}