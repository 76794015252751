import basic from "./basic"
import admin from "./admin"
import activiti from "./activiti"
import route from "./route"
import role from "./role"
import error from "./error"
export default {
  route,
  role,
  admin,
  error,
  ...basic,
  ...activiti,
}
