import { defineStore } from "pinia";
import { RoutesBuilder, constantRoutes } from "@/router";
import { listRoutesApi } from "@/api/admin/auth";
import { checkLicense } from "@/api/auth";
import { useAppStore } from "@/store/modules/app";
import { RouteRecordRaw } from "vue-router";
import { IMenu } from "@/api/admin/menu/types";
import { getLock } from "@/utils/lock";
import { store } from "@/store";

export const useMenuStore = defineStore("menus", () => {

  const routes = ref<RouteRecordRaw[]>([]);
  // 混合模式左侧菜单
  const mixLeftMenus = ref<RouteRecordRaw[]>([]);
  // 路由是否已加载
  const isRoutesLoaded = ref(false);
  // 混合模式左侧菜单
  routes.value = constantRoutes();
  
    /**
   * 混合模式菜单下根据顶部菜单路径设置左侧菜单
   *
   * @param topMenuPath - 顶部菜单路径
   */
    const setMixLeftMenus = (topMenuPath: string) => {
      const matchedItem = routes.value.find((item) => item.path === topMenuPath);
      if (matchedItem && matchedItem.children) {
        mixLeftMenus.value = matchedItem.children;
      }
    };

  async function initMenus(): Promise<void> {
    if (isRoutesLoaded.value) return;
    console.info("load menus...")
    await listRoutesApi().then(
      (menus: any) => {
        const filterSeleted = menus[0]
        let menuRoots: IMenu[] = menus[1];
        //console.log(menuRoots)
        let appendRoutes = RoutesBuilder(menuRoots, filterSeleted);
        routes.value = constantRoutes().concat(appendRoutes);

        isRoutesLoaded.value = true;
        // console.log("routes:", routes.value)
        // useAppStore().addRoutes(appendRoutes);
        checkLicense().then(data => {
          if (data.error) {
            var msg = "";
            if (data.pinError)
              msg = 'PIN码错误:' + data.pinError;
            if (data.endDate)
              msg += ' 软件使用截止日期:' + data.endDate;
            if (data.limitedCount)
              msg += ' 软件使用人数不能超过:' + data.limitedCount;
            useAppStore().fail(msg);
          }
        }, () => { });
        console.log("end list routes")
      }).catch(err => {
        console.log("init menus:", err)

        return Promise.reject(err);
      });
    console.log("end init menus")

  }
  function logout() {
    isRoutesLoaded.value = false;
    //routes.value = [];
  }

  return {
    setMixLeftMenus,
    mixLeftMenus,
    initMenus,
    routes,
    isRoutesLoaded,
    logout
  }
});
// 非setup
export function useMenuStoreHook() {
  return useMenuStore(store);
}


