import axios, { InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import { useAppStoreHook } from '@/store/modules/app';
import { useUserStoreHook } from '@/store/modules/user';
import { ElMessage } from 'element-plus';

const tokenName = "x-auth-token";


// 创建 axios 实例
const service = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_API,
  timeout: 50000,
  headers: { 'Content-Type': 'application/json;charset=utf-8' }
});



// 请求拦截器
service.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const global = config.headers["global"];
    if (!global || global != "nosession") {
        const session = useAppStoreHook().getSession();
        if (null != session) {
            
            //console.log("use token:" + session.id)
            config.headers[tokenName] = session.id;
            
        }
    }
    
    config.headers["X-Tag"] = "axios";
    //console.log("request :", config)
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response: AxiosResponse) => {
    // console.log("res", response)
    if (response["errMsg"] && response["errMsg"] !== "request:ok") {
			console.error("res", response)
			return Promise.resolve(null);
		}
    const { data, headers, config } = response;
    if (tokenName && headers[tokenName]) {
      console.log("get token:" + headers[tokenName])
      useAppStoreHook().newSession(headers[tokenName]);
    }

    if (config.method == "head") {
      return Promise.resolve(headers);
    }

    // 响应数据为二进制流处理(Excel导出)
    if (data instanceof Blob) {
      // const contentType = response.headers['content-type'] ;
      // if (contentType.indexOf("xml") > -1) {//实际返回的是文本
      //   return Promise.resolve(data);
      // }
      const contentDisposition = headers['content-disposition'];
      if (!contentDisposition) {//不需要下载
        return Promise.resolve(data);
      }
      // 使用正则表达式匹配文件名
      const match = contentDisposition.split("filename*=utf-8''");
      const filename = decodeURIComponent(match[1]);
      // 处理文件名，去除引号等
      const cleanFilename = filename.trim().replace(/\"/g, '');
      const blob = new Blob([response.data], { type: response.headers['content-type'] }); // 创建Blob对象
      const downloadUrl = URL.createObjectURL(blob); // 创建下载链接
      const link = document.createElement('a'); // 创建a标签
      link.href = downloadUrl;
      link.download = cleanFilename; // 设置下载的文件名
      document.body.appendChild(link); // 将a标签添加到文档中
      link.click(); // 触发下载
      URL.revokeObjectURL(downloadUrl); // 清理资源
      return Promise.resolve(true);
    }
    if (data === "") {
      return Promise.resolve(null);
    }
    return Promise.resolve(data);

  },
  (error: any) => {
    if (error.response) {
      let { status, data, config } = error.response;

      switch (status) {
        case 401:
          const requestConfig = error.config
          const refreshToken = requestConfig.headers["global"] && requestConfig.headers["global"] == "refresh"
          if (!refreshToken && config.url != "/logout" && config.url != "/login") {
            useUserStoreHook().resetValue();
          }

          return Promise.reject(data);
        case 403:
          let msg403 = '<span class="i403">权限不足</span>';
          if (data) {
            if (data.message)
              msg403 = `<span class="i403">${data.message}</span>`;
            else
              msg403 = `<span class="i403">${data}</span>`;
          }
          ElMessage.error({
            dangerouslyUseHTMLString: true,
            customClass: 'errorInfo',
            message: msg403,
            type: 'warning',
            duration: 2000
          })
          break;
        case 404:
          ElMessage.error({
            dangerouslyUseHTMLString: true,
            customClass: 'errorInfo',
            message: `<span class="i404">${config.url}资源不存在</span>`,
            type: 'warning',
            duration: 2000
          })
          break;
        case 417:
          useAppStoreHook().sendErrorReport(data)
          break;
        case 424:
          var msg = '<span class="i424">数据约束异常</span>';
          ElMessage.error({
            dangerouslyUseHTMLString: true,
            customClass: 'errorInfo',
            message: msg,
            type: 'error',
            duration: 2000
          })
          break;
        default:
          var ex = '<span class="err">网络错误</span>';
          if (data) {
            ex = '<span class="err">' + (data.message ? data.message : data) + '</span>';
          }
          ElMessage.error({
            dangerouslyUseHTMLString: true,
            customClass: 'errorInfo',
            message: ex,
            type: 'error',
            duration: 2000
          })
          break;
      }
      return Promise.reject(data.message? data.message : data);
    }
    return Promise.reject(error);

  });

export function appendToken(api: string, refresh=false) {
  const session = useAppStoreHook().getSession();
  if (null != session) {
    const r = refresh ? Math.floor(Math.random() * 10) : "1";
    const url = `${getRootPath()}${api}?${tokenName}=${session.id}&r=${r}`;
    return url;
  } else {
    const r = refresh ? Math.floor(Math.random() * 10) : "1";
    const url = `${getRootPath()}${api}?r=${r}`;

    return url;
  }

}
function getRootPath() {
  const baseURL = import.meta.env.VITE_APP_BASE_API
  if (baseURL)
    return baseURL
  const currentPath = window.document.location.href;
  const pathName = window.document.location.pathname;
  const pos = currentPath.indexOf(pathName);
  const localhostPath = currentPath.substring(0, pos);

  return localhostPath;
}

// 导出 axios 实例
export default service;
